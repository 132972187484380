import { LangIsoCode } from '@/models/enums/LangIsoCode';

export const fallbackLocale = 'en';
export const customerLocales: LangIsoCode[] = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'pt'];
export const uiLocales: LangIsoCode[] = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'ro'];

export const supportedLocalesCustomer = (locale: LangIsoCode): LangIsoCode => {
  return getInArrayOrFallback<LangIsoCode>(customerLocales, locale, fallbackLocale);
};

export const supportedLocalesUI = (locale: LangIsoCode): LangIsoCode => {
  return getInArrayOrFallback<LangIsoCode>(uiLocales, locale, fallbackLocale);
};

const getInArrayOrFallback = <T>(arr: T[], value: T, fallback: T): T => {
  if (arr.includes(value)) {
    return value;
  } else {
    return fallback;
  }
};
